<template>
    <div class="dashboard-container">
      
        <div class="app-container" style="margin-bottom: 20px">
        <el-row>
            <el-col :span="12" align="left">
                <div style="display: flex; width: 70%">
            <el-input
              v-model="searchtitle"
              placeholder="请输入相册名称"
              clearable
              @clear="clear"
            />
            <el-button
              slot="append"
              type="primary"
              style="margin-left: 5px"
              @click="searchTitle"
              >查询</el-button>
          </div>
        </el-col>
          <el-col :span="12" align="right">
            <el-button class="el-icon-edit" type="primary" @click="addSchoolInfo"
              >添加相册</el-button
            >
          </el-col>
        </el-row>
      </div>
        <!-- 添加或编辑类别 -->
      <el-dialog :title="title" :visible="showDialog" @close="btnCancel">
        <!-- 表单 -->
        <el-form
          ref="perForm"
          :model="formData"
          :rules="rules"
          label-width="200px"
        >
          <el-form-item label="标题名称" prop="title">
            <el-input
              v-model="formData.title"
              placeholder="请输入标题名称"
              style="width: 50%"
            />
          </el-form-item>
        </el-form>
        <el-row slot="footer" type="flex" justify="center">
          <el-col :span="6">
            <el-button size="small" type="primary" @click="btnOK">
              确定
            </el-button>
            <el-button size="small" @click="btnCancel">取消</el-button>
          </el-col>
        </el-row>
      </el-dialog>
         <div class="tab">
        <el-table
          :header-cell-style="{ background: '#F2F6FC', color: '#606266' }"
          :data="list"
          border
          style="width: 100%"
          ref="list"
          class="emp_table"
          v-if="list"
        >
      
                <el-table-column
            align="center"
            prop="title"
            label="相册名称"
            :show-overflow-tooltip="true"
          />
          <el-table-column
            align="center"
            prop="fileNum"
            label="文件数"
            :show-overflow-tooltip="true"
          />
          <el-table-column
          align="center"
          prop="upStatus"
          label="上下架状态"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            {{ scope.row.upStatus | fmatStatus }}
          </template>
        </el-table-column>
           
          <el-table-column
            align="center"
            prop="createTime"
            label="创建时间"
            width="200"
          >
            <template slot-scope="scope">
              {{ scope.row.createTime | getLocalTime }}
            </template>
          </el-table-column>
              <el-table-column align="center" label="操作" width="251px">
            <template slot-scope="scope">
                <el-button
                type="text"
                size="small"
                @click="fileList(scope.row)"
              >
                文件列表
              </el-button>
              <el-button
                type="text"
                size="small"
                @click="updateAlbum(scope.row)"
              >
                编辑
              </el-button>
              <el-button
              type="text"
              size="small"
              @click="setAlbumStatus(scope.row)"
            >
              {{ scope.row.upStatus == 1 ? "上架" : "下架" }}
            </el-button>
              <el-button
                type="text"
                size="small"
                @click="delAlbum(scope.row)"
              >
                删除
              </el-button>
            </template>
          </el-table-column>
       
        </el-table>
        <!-- 分页 -->
       <pagination
        v-show="total>0"
        :total="total"
        :page.sync="queryParams.page"
        :limit.sync="queryParams.pageSize"
        @pagination="getAlbumList"
      />
      </div>
    </div>
  </template>
  
  
  <script>
  import {
    updateAlbum,
    addAlbum,
    delAlbum,
    setAlbumStatus,
   getAlbumList
  } from "../../request/http";
  export default {
    data() {
      return {
        title:'',
          showDialog: false, //显示隐藏
     
        searchtitle: "",
        list: "", 
        total: 0,
        queryParams: {
          page: 1,
          pageSize: 10,
        },
         formData: {
           title:'',
        },
        rules: {
          title: [
            { required: true, message: "相册名称不能为空", trigger: "blur" },
          ],
        
        },
      };
    },
    created() {
       this.getAlbumList()
     
    },
    methods: {
        fileList(row){
            let albumId =row.albumId
            this.$router.push({
        path: "/file",
        query: { albumId },
      }); 
        },





        async setAlbumStatus(row) {
    

let id = row.albumId;
    let res = await setAlbumStatus({ albumId: id });
    console.log(res.code);
    res.code == 0 &&
      this.$message({
        type: "success",
        message: "设置成功",
      });
      
      res.code !== 0 &&
      this.$message({
        type: "warning",
        message: res.msg,
      });
      
   
      this.getAlbumList();
  },




        clear() {
      this.queryParams = {}
      this.getAlbumList()
      this.searchtitle = ''
    
    },
   // 查询相册名称
   async searchTitle() {
      this.queryParams = {
        page: 1,
        pageSize: 10,
        title: this.searchtitle,
       
      }
      const { data, count } = await getAlbumList(this.queryParams)
      this.list = data
      this.total = count
    },
       // 打开添加的窗口
      addSchoolInfo() {
        // this.imageUrl=null
        this.title = "添加相册";
        this.showDialog = true;
      },
  
      // 打开编辑类别的窗口
      updateAlbum(val) {
        console.log(val);
        this.title = "编辑相册信息";

        this.formData = {
         albumId: val.albumId,
         title: val.title,
       
    
        };
        
        this.showDialog = true;
      },
      
      // 确认
      btnOK() {
        this.$refs.perForm
          .validate()
          .then(() => {
            if (this.formData.albumId) {
             
              return updateAlbum(this.formData);
            } else {
              return addAlbum(this.formData);
            }
          })
          .then(() => {
            //  提示消息
            if (this.formData.albumId) {
              this.$message.success("编辑成功");
            } else {
              this.$message.success("新增成功");
            }
            
            this.getAlbumList();
            this.showDialog = false;
          });
      },
  
      //弹窗取消函数
      btnCancel() {
      
        this.formData = {};
        this.$refs.perForm.resetFields();
        this.showDialog = false;
      },
      //删除
      async delAlbum(row) {
        try {
          await this.$confirm("确定要删除吗");
          await delAlbum({albumId: row.albumId });
          const totalPage = Math.ceil(
            (this.total - 1) / this.queryParams.pageSize
          );
          this.queryParams.page =
            this.queryParams.page > totalPage ? totalPage : this.queryParams.page;
          this.queryParams.page =
            this.queryParams.page < 1 ? 1 : this.queryParams.page;
          this.getAlbumList();
  
          this.$message.success("删除成功");
        } catch (error) {
          console.log(error);
        }
      },
      
        
      async getAlbumList() {
        const { data, count } = await getAlbumList(this.queryParams)
        this.list = data
        this.total = count
      },
    },
  };
  </script>
  
  <style>
  </style>